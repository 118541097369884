import React, { useState, useEffect } from 'react';
import { CreateMealStyle } from '../meal.styles';
import { getCountries } from 'country-state-picker';
import { useDispatch, useSelector } from 'react-redux';
import { create_meal, get_single_meal, update_meal } from '../redux/reducer';
import Details from './Details';
import Ingredients from './Ingredients';
import { getCreateLoading, getLoading, getMeal } from '../redux/selector';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import LoadingDataUi from '@app/components/loading';
import Resizer from 'react-image-file-resizer';
import { getMealCategories } from '@app/pages/mealCategories/redux/selector';
import { get_meal_categories } from '@app/pages/mealCategories/redux/reducer';
import { get_health_conditions } from '@app/pages/health/redux/reducer';
import { getHealthConditions } from '@app/pages/health/redux/selectors';
import Nutrients from './Nutrients';
import HealthCondition from './health-condition';

const CreateMeal = () => {
  const [countries, setCountries] = useState([]);
  const [images, setImages] = useState(null);
  const dispatch = useDispatch();
  const loading = useSelector(getCreateLoading);
  const loadMeal = useSelector(getLoading);
  const params = useParams();
  const meal = useSelector(getMeal);
  const categories = useSelector(getMealCategories);
  const [mealCatgories, setMealCatgories] = useState([]);
  const healthConditions = useSelector(getHealthConditions);
  const [health, setHealth] = useState([]);

  useEffect(() => {
    dispatch(get_meal_categories({ page: 1, limit: 1000 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(get_health_conditions({ page: 1, limit: 1000 }));
  }, [dispatch]);

  const [initialValues, setInitialValues] = useState({
    name: '',
    description: '',
    types: '',
    countries: ['Nigeria'],
    prep_time: 0,
    ingredients: [{ value: '' }],
    categories: [],
    health_conditions: [],
    nutrients: [{ name: '', value: '' }],
    incompatible_medical_conditions: [''],
    instructions: [
      {
        value: ''
      }
    ]
  });

  useEffect(() => {
    if (window.location.pathname.includes('create')) {
      setInitialValues({
        name: '',
        description: '',
        types: '',
        countries: [{ value: 'Nigeria', label: 'Nigeria' }],
        prep_time: 0,
        ingredients: [{ value: '' }],
        nutrients: [{ name: '', value: '' }],
        categories: [],
        health_conditions: [],
        incompatible_medical_conditions: [''],
        instructions: [
          {
            value: ''
          }
        ]
      });
    } else {
      setImages(meal?.image);
      const ingredientsArray = meal?.ingredients?.map((data) => ({
        value: data
      }));
      const instructionsArray = meal?.instructions?.map((data) => ({
        value: data
      }));
      const countries = meal?.countries?.map((data) => ({
        value: data,
        label: data
      }));
      const types = meal?.types?.map((data) => ({ value: data, label: data }));
      const categories = meal?.categories?.map((data) => ({
        value: data._id,
        label: data.name
      }));

      const health = meal?.health_conditions?.map((data) => ({
        value: data._id,
        label: data.name
      }));
      const nutrientsArray = meal?.nutrients?.map((data) => ({
        name: data.name,
        value: data.value
      }));

      const incompatible_medical_conditions =
        meal?.incompatible_medical_conditions?.map((data) => ({
          idx: data
        }));

      setInitialValues({
        name: meal?.name,
        description: meal?.description,
        countries: countries,
        prep_time: meal?.prep_time,
        ingredients: ingredientsArray,
        instructions: instructionsArray,
        categories: categories,
        health_conditions: health,
        incompatible_medical_conditions,
        nutrients:
          meal?.nutrients?.length >= 1 ? nutrientsArray : [{ name: '', value: '' }],
        types: types
      });
    }
  }, [meal, params]);

  useEffect(() => {
    if (params.id) {
      dispatch(get_single_meal(params.id));
    }
  }, [dispatch, params]);

  useEffect(() => {
    const countries = getCountries();
    const result = countries.map((data) => {
      return {
        value: data.name,
        label: data.name
      };
    });
    setCountries(result);
  }, []);

  useEffect(() => {
    const response = categories.map((data) => {
      return {
        value: data._id,
        label: data.name
      };
    });
    setMealCatgories(response);
  }, [categories]);

  useEffect(() => {
    const response = healthConditions.map((data) => {
      return {
        value: data._id,
        label: data.name
      };
    });
    setHealth(response);
  }, [healthConditions]);

  const handlePickImage = (e) => {
    const file = e.target.files[0];
    Resizer.imageFileResizer(
      file,
      400,
      500,
      'JPEG',
      900,
      0,
      (uri) => {
        setImages(uri);
      },
      'file'
    );
  };

  const options = [
    { value: 'Breakfast', label: 'Breakfast' },
    { value: 'Lunch', label: 'Lunch' },
    { value: 'Dinner', label: 'Dinner' }
  ];

  const handleNewSubmit = (doc) => {
    let ingredientsArray = Object.values(doc.ingredients);
    let instructionsArray = Object.values(doc.instructions);
    let stringArray = ingredientsArray.map((data) => data.value);
    let instString = instructionsArray.map((data) => data.value);
    const types = doc.types.map(
      (doc) => doc.value.charAt(0).toUpperCase() + doc.value.slice(1)
    );
    const countries = doc.countries.map((doc) => doc.value);
    const categories = doc.categories.map((doc) => doc.value);
    const health = doc.health_conditions.map((doc) => doc.value);
    const incompatible_medical_conditions = doc.incompatible_medical_conditions.map(
      (doc) => doc.idx
    );

    let data = {
      ...doc,
      ingredients: stringArray,
      instructions: instString,
      types: types,
      countries,
      categories: categories,
      health_conditions: health,
      incompatible_medical_conditions: incompatible_medical_conditions,
      images
    };
    if (params.id) {
      dispatch(update_meal({ result: data, id: params.id }));
    } else {
      dispatch(create_meal(data));
    }
  };

  const removeImage = () => {
    setImages('');
  };

  return (
    <CreateMealStyle>
      {loadMeal ? (
        <LoadingDataUi />
      ) : (
        <>
          <div className="header">
            <Link className="link" to="/meals">
              Meals
            </Link>

            <MdChevronLeft color="#605D66" />

            <p>{params.id ? 'Edit Meal' : 'Add Meal'}</p>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={handleNewSubmit}
            enableReinitialize={true}
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form>
                <div className="body">
                  <div className="left_flex">
                    <div className="box">
                      <div className="box_title">Details</div>
                      <div className="children">
                        <Details
                          value={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          images={images}
                          countries={countries}
                          handlePickImage={handlePickImage}
                          options={options}
                          params={params}
                          removeImage={removeImage}
                          categories={mealCatgories}
                          health={health}
                        />
                      </div>
                    </div>

                    <div className="box">
                      <div className="box_title">Health Conditions</div>
                      <div className="children">
                        <HealthCondition
                          value={values}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>

                    <div className="box">
                      <div className="box_title">Nutrients</div>
                      <div className="children">
                        <Nutrients value={values} setFieldValue={setFieldValue} />
                      </div>
                    </div>

                    <div className="box">
                      <div className="box_title">Others</div>
                      <div className="children">
                        <Ingredients value={values} setFieldValue={setFieldValue} />
                      </div>
                    </div>
                  </div>
                  <div className="right_flex">
                    <button
                      type="submit"
                      style={{ width: '100%' }}
                      disabled={loading}
                    >
                      {loading ? 'Saving' : 'Save'}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </CreateMealStyle>
  );
};

export default CreateMeal;
